import React from 'react';
import styled from 'styled-components';
import Logo_Principal from '../../assets/Logo_Principal.png';

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: ${props => props.light ? 'white' : '#000'};
  width: 285px;
  
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;

const Logo = ({ light }) => {
  return (
    <LogoWrapper light={light}>
      <img src={Logo_Principal} alt="Zion Logo" />
    </LogoWrapper>
  );
};

export default Logo; 