import React from 'react';
import styled from 'styled-components';

const DescripcionContainer = styled.section`
  padding: 120px 0;
  background-color: #fff;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: #1C212D;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: #666;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 3rem;
  line-height: 1.6;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
  padding: 0 1rem;
`;

const FeatureCard = styled.div`
  background: #f8f9fa;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const FeatureIcon = styled.div`
  font-size: 2rem;
  color: #F3BA30;
  margin-bottom: 1rem;
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  color: #1C212D;
  margin-bottom: 1rem;
`;

const FeatureDescription = styled.p`
  color: #666;
  line-height: 1.6;
`;

const DescripcionGeneral = () => {
  return (
    <DescripcionContainer id="descripcion">
      <ContentWrapper>
        <Title>Sistema Financiero Integral ZION</Title>
        <Subtitle>
          Una plataforma innovadora diseñada para transformar la gestión financiera 
          de tu empresa, ofreciendo soluciones inteligentes y seguras para el manejo 
          de tus recursos.
        </Subtitle>

        <FeaturesGrid>
          <FeatureCard>
            <FeatureIcon>💼</FeatureIcon>
            <FeatureTitle>Gestión Financiera Completa</FeatureTitle>
            <FeatureDescription>
              Control total sobre ingresos, gastos, presupuestos y flujos de efectivo 
              con herramientas intuitivas y reportes detallados.
            </FeatureDescription>
          </FeatureCard>

          <FeatureCard>
            <FeatureIcon>🔒</FeatureIcon>
            <FeatureTitle>Seguridad Avanzada</FeatureTitle>
            <FeatureDescription>
              Protección de datos de última generación con encriptación de nivel bancario 
              y autenticación multifactor para tu tranquilidad.
            </FeatureDescription>
          </FeatureCard>

          <FeatureCard>
            <FeatureIcon>📊</FeatureIcon>
            <FeatureTitle>Análisis en Tiempo Real</FeatureTitle>
            <FeatureDescription>
              Dashboards interactivos y reportes personalizados que te permiten tomar 
              decisiones informadas al instante.
            </FeatureDescription>
          </FeatureCard>
        </FeaturesGrid>
      </ContentWrapper>
    </DescripcionContainer>
  );
};

export default DescripcionGeneral; 