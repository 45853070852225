import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const CarouselContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
`;

const CarouselSlide = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: opacity 1.5s ease-in-out;
  background: linear-gradient(rgba(28, 33, 45, 0.9), rgba(28, 33, 45, 0.9)),
              url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const images = [
  'https://images.unsplash.com/photo-1634704784915-aacf363b021f',
  'https://images.unsplash.com/photo-1634704784915-aacf363b021f'
];

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % images.length);
    }, 8000);

    return () => clearInterval(timer);
  }, []);

  return (
    <CarouselContainer>
      {images.map((image, index) => (
        <CarouselSlide
          key={index}
          active={index === currentSlide}
          image={image}
        />
      ))}
    </CarouselContainer>
  );
};

export default Carousel; 