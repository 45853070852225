import React, { useState, useEffect, useCallback } from 'react';
import { Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import Logo from './components/Logo';
import HeroSection from './components/HeroSection';
import DescripcionGeneral from './components/DescripcionGeneral';
import Caracteristicas from './components/Caracteristicas';
import Beneficios from './components/Beneficios';
import NominaComponent from './components/Nomina';
import RegisterForm from './components/RegisterForm';
import { useResponsiveMenu } from './hooks/useResponsiveMenu';
import Footer from './components/Footer';

const AppWrapper = styled.div`
  min-height: 100vh;
`;

const Header = styled.header`
  padding: 1rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease;
  background-color: ${props => props.isScrolled ? 'rgba(255, 255, 255, 0.95)' : 'rgba(255, 255, 255, 0.95)'};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;

  @media (max-width: 768px) {
    position: relative;
  }
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  @media (max-width: 768px) {
    position: fixed;
    top: 80px;
    right: ${props => props.isOpen ? '0' : '-100%'};
    width: 60%;
    max-width: 300px;
    height: auto;
    max-height: 70vh;
    background: linear-gradient(145deg, #ffffff, #f8f9fa);
    flex-direction: column;
    padding: 1.5rem;
    transition: right 0.3s ease-in-out;
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    justify-content: flex-start;
    border-radius: 0 0 0 20px;
    overflow-y: auto;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      background: linear-gradient(to bottom, #F3BA30, #FFD700);
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: linear-gradient(to right, #F3BA30, #FFD700);
      border-radius: 0 0 0 20px;
    }

    a {
      width: 100%;
      padding: 0.8rem 1rem;
      text-align: left;
      border-radius: 8px;
      transition: all 0.3s ease;
      font-size: 1rem;
      
      &:hover {
        background: rgba(243, 186, 48, 0.1);
        padding-left: 1.5rem;
      }

      &.active {
        background: rgba(243, 186, 48, 0.2);
        color: #1C212D;
        font-weight: bold;
      }
    }

    button {
      margin-top: 1rem;
      width: 100%;
      padding: 0.8rem 2rem;
    }
  }
`;

const NavLink = styled.a`
  text-decoration: none;
  color: #1C212D;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: all 0.3s ease;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 2px;
    background-color: #F3BA30;
    transition: width 0.3s ease;
  }
  
  &:hover {
    color: #F3BA30;
    
    &::after {
      width: 80%;
    }
  }

  &.active {
    color: #F3BA30;
    
    &::after {
      width: 80%;
    }
  }
`;

const NavButton = styled.button`
  background-color: #F3BA30;
  color: #1C212D;
  padding: 1rem 3rem;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  
  &:hover {
    background-color: #FFD700;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 1rem;
  }
`;
const MenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  position: relative;
  z-index: 1200;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 24px;
    background: linear-gradient(145deg, #F3BA30, #FFD700);
    border-radius: 8px;
    padding: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    span {
      display: block;
      height: 2px;
      width: 100%;
      background-color: #1C212D;
      border-radius: 2px;
      transition: all 0.3s ease;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        right: -2px;
        top: 0;
        width: 4px;
        height: 2px;
        background-color: #FFD700;
        border-radius: 2px;
      }
    }

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 4px 8px rgba(243, 186, 48, 0.3);
    }

    ${props => props.isOpen && `
      span:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
      }
      span:nth-child(2) {
        opacity: 0;
      }
      span:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
      }
    `}
  }
`;

function App() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const [isRegisterFormOpen, setIsRegisterFormOpen] = useState(false);
  const { isMenuOpen, setIsMenuOpen, isMobile } = useResponsiveMenu();

  const handleClickOutside = useCallback((e) => {
    if (isMenuOpen && isMobile) {
      const navLinks = document.querySelector('[data-nav-links]');
      const menuButton = document.querySelector('[data-menu-button]');
      
      if (navLinks && !navLinks.contains(e.target) && !menuButton.contains(e.target)) {
        setIsMenuOpen(false);
      }
    }
  }, [isMenuOpen, isMobile, setIsMenuOpen]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isMenuOpen, isMobile, handleClickOutside]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);

      // Detectar sección activa
      const sections = ['descripcion', 'caracteristicas', 'beneficios', 'nomina'];
      const windowHeight = window.innerHeight;
      const scrollPosition = window.scrollY;
      const documentHeight = document.documentElement.scrollHeight;

      // Verificar si estamos cerca del final de la página
      const isNearBottom = scrollPosition + windowHeight >= documentHeight - 100;

      if (isNearBottom) {
        setActiveSection('nomina');
        return;
      }

      const current = sections.find(section => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          return rect.top <= 120 && rect.bottom >= 120;
        }
        return false;
      });
      
      if (current) {
        setActiveSection(current);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <AppWrapper>
      <Header isScrolled={isScrolled}>
        <Nav>
          <Logo light={false} />
          {isMobile && (
            <MenuButton onClick={() => setIsMenuOpen(!isMenuOpen)} isOpen={isMenuOpen} data-menu-button>
              <span></span>
              <span></span>
              <span></span>
            </MenuButton>
          )}
          <NavLinks isOpen={isMenuOpen} data-nav-links>
            {['descripcion', 'caracteristicas', 'beneficios', 'nomina'].map((section) => (
              <NavLink 
                key={section}
                href={`#${section}`}
                className={activeSection === section ? 'active' : ''}
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection(section);
                  setIsMenuOpen(false);
                  setIsRegisterFormOpen(false);
                }}
              >
                {section === 'descripcion' ? 'Descripción general' :
                 section === 'caracteristicas' ? 'Características' :
                 section === 'beneficios' ? 'Beneficios' :
                 'Nómina de sueldos'}
              </NavLink>
            ))}
            <NavButton onClick={() => {
              setIsRegisterFormOpen(true);
              setIsMenuOpen(false);
            }}>
              Contactanos
            </NavButton>
          </NavLinks>
        </Nav>
      </Header>
      <Routes>
        <Route path="/" element={
          <>
            <HeroSection onRegisterClick={() => setIsRegisterFormOpen(true)} />
            <DescripcionGeneral />
            <Caracteristicas />
            <Beneficios />
            <NominaComponent />
          </>
        } />
      </Routes>
      <RegisterForm 
        isOpen={isRegisterFormOpen} 
        onClose={() => setIsRegisterFormOpen(false)} 
      />
      <Footer />
    </AppWrapper>
  );
}

export default App;