import React from 'react';
import styled from 'styled-components';

const CaracteristicasContainer = styled.section`
  padding: 120px 0;
  background-color: #f8f9fa;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: #1C212D;
  margin-bottom: 3rem;
  text-align: center;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;
`;

const Feature = styled.div`
  text-align: center;
  padding: 2rem;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const FeatureIcon = styled.div`
  font-size: 3rem;
  color: #F3BA30;
  margin-bottom: 1.5rem;
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  color: #1C212D;
  margin-bottom: 1rem;
`;

const FeatureDescription = styled.p`
  color: #666;
  line-height: 1.6;
`;

const Caracteristicas = () => {
  return (
    <CaracteristicasContainer id="caracteristicas">
      <ContentWrapper>
        <Title>Características Principales</Title>
        <GridContainer>
          <Feature>
            <FeatureIcon>📱</FeatureIcon>
            <FeatureTitle>Acceso Multiplataforma</FeatureTitle>
            <FeatureDescription>
              Gestiona tu sistema desde cualquier dispositivo, en cualquier momento y lugar.
            </FeatureDescription>
          </Feature>

          <Feature>
            <FeatureIcon>🔄</FeatureIcon>
            <FeatureTitle>Sincronización en Tiempo Real</FeatureTitle>
            <FeatureDescription>
              Todos los datos se actualizan instantáneamente en todos los dispositivos conectados.
            </FeatureDescription>
          </Feature>

          <Feature>
            <FeatureIcon>📊</FeatureIcon>
            <FeatureTitle>Reportes Personalizados</FeatureTitle>
            <FeatureDescription>
              Genera informes detallados según tus necesidades específicas.
            </FeatureDescription>
          </Feature>
        </GridContainer>
      </ContentWrapper>
    </CaracteristicasContainer>
  );
};

export default Caracteristicas; 