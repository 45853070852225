import React from 'react';
import styled from 'styled-components';
import Carousel from '../Carousel';

const HeroContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding-top: 120px;
  padding-bottom: 40px;
  
  @media (max-width: 768px) {
    padding-top: 100px;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 2;
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  min-height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media (max-width: 768px) {
    padding: 10px;
    min-height: calc(100vh - 140px);
  }
`;

const CreditCard = styled.img`
  position: absolute;
  width: 400px;
  height: auto;
  filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.3));
  transition: transform 0.3s ease;

  &.card1 {
    top: 20%;
    right: -100px;
    transform: rotate(15deg);
  }

  &.card2 {
    bottom: 20%;
    left: -100px;
    transform: rotate(-15deg);
  }

  @media (max-width: 768px) {
    width: 300px;
    
    &.card1 {
      top: 10%;
      right: -150px;
    }
    
    &.card2 {
      bottom: 10%;
      left: -150px;
    }
  }
`;

const HeroTitle = styled.h1`
  font-size: clamp(2.5rem, 5vw, 5rem);
  margin-bottom: 1rem;
  font-weight: bold;
  line-height: 1.2;
  word-wrap: break-word;
  overflow-wrap: break-word;
  position: relative;
  padding-top: 60px;
  
  br {
    display: inline-block;
  }
  
  @media (max-width: 768px) {
    font-size: clamp(2rem, 4vw, 3rem);
    padding-top: 40px;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  max-width: 600px;
  padding: 0 1rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const AccessButton = styled.button`
  background-color: #F3BA30;
  color: #1C212D;
  padding: 1rem 3rem;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  
  &:hover {
    background-color: #FFD700;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const HeroSection = ({ onRegisterClick }) => {
  return (
    <HeroContainer>
      <CreditCard 
        className="card1"
        src="https://raw.githubusercontent.com/adrianhajdin/project_hoobank/main/src/assets/card.png" 
        alt="Credit Card 1" 
      />
      <CreditCard 
        className="card2"
        src="https://raw.githubusercontent.com/adrianhajdin/project_hoobank/main/src/assets/robot.png" 
        alt="Credit Card 2" 
      />
      <Carousel />
      <ContentWrapper>
        <HeroTitle>Ábrete a<br />un nuevo<br />horizonte</HeroTitle>
        <HeroSubtitle>
          Administra tus finanzas desde cualquier lugar y en cualquier momento.
        </HeroSubtitle>
        <AccessButton onClick={onRegisterClick}>Contactanos</AccessButton>
      </ContentWrapper>
    </HeroContainer>
  );
};

export default HeroSection;