import React, { useState } from 'react';
import styled from 'styled-components';
import emailjs from '@emailjs/browser';

emailjs.init("TWScH2TcjSTaWOnm2");

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 999;
  padding: 200px 20px 20px;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding-top: 120px;
  }

  @media (min-width: 769px) and (max-width: 1200px) {
    padding-top: 160px;
  }
`;

const ModalContent = styled.div`
  background: white;
  padding: 3rem;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  position: relative;
  margin: 0 auto;
  z-index: 998;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: linear-gradient(145deg, #F3BA30, #FFD700);
  border: none;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 1.5rem;
  cursor: pointer;
  color: #1C212D;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
  &:hover {
    transform: rotate(90deg) scale(1.1);
    box-shadow: 0 4px 8px rgba(243, 186, 48, 0.3);
    background: linear-gradient(145deg, #FFD700, #F3BA30);
  }

  &:active {
    transform: scale(0.95);
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  
  @media (max-width: 480px) {
    gap: 0.8rem;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  font-weight: 500;
  color: #1C212D;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
  
  @media (max-width: 480px) {
    padding: 0.5rem;
    font-size: 0.9rem;
  }
`;

const SubmitButton = styled.button`
  background-color: #F3BA30;
  color: #1C212D;
  padding: 0.8rem;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  
  @media (max-width: 480px) {
    padding: 0.7rem;
    font-size: 0.9rem;
  }
`;

const TextArea = styled.textarea`
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  resize: vertical;
  min-height: 80px;
  max-height: 200px;
  width: 100%;
  
  @media (max-width: 480px) {
    padding: 0.5rem;
    font-size: 0.9rem;
    min-height: 60px;
  }
`;

const StatusMessage = styled.div`
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  text-align: center;
  
  ${props => props.status === 'success' && `
    background-color: #d4edda;
    color: #155724;
  `}
  
  ${props => props.status === 'error' && `
    background-color: #f8d7da;
    color: #721c24;
  `}
`;

const LoadingSpinner = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #F3BA30;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-left: 10px;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const FormContainer = styled.div`
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  padding-right: 10px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const RegisterForm = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    nombre: '',
    empresa: '',
    email: '',
    telefono: '',
    detalles: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const templateParams = {
        to_email: 'ricardoalfaro89@hotmail.com',
        from_name: formData.nombre,
        from_email: formData.email,
        empresa: formData.empresa,
        telefono: formData.telefono,
        mensaje: formData.detalles
      };

      await emailjs.send(
        'service_wxbx56s',
        'template_3c9or07',
        templateParams,
        'TWScH2TcjSTaWOnm2'
      );

      setSubmitStatus('success');
      setTimeout(() => {
        onClose();
        setFormData({
          nombre: '',
          empresa: '',
          email: '',
          telefono: '',
          detalles: ''
        });
      }, 2000);

    } catch (error) {
      console.error('Error al enviar el correo:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <ModalOverlay onClick={handleOverlayClick}>
      <ModalContent>
        <CloseButton onClick={onClose}>×</CloseButton>
        <h2 style={{ marginBottom: '2rem', textAlign: 'center' }}>Contáctenos</h2>
        <FormContainer>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label>Nombre completo *</Label>
              <Input
                type="text"
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Empresa (opcional)</Label>
              <Input
                type="text"
                name="empresa"
                value={formData.empresa}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Email *</Label>
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Teléfono *</Label>
              <Input
                type="tel"
                name="telefono"
                value={formData.telefono}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Detalles de su consulta</Label>
              <TextArea
                name="detalles"
                value={formData.detalles}
                onChange={handleChange}
                rows={4}
                placeholder="Cuéntenos más sobre su consulta..."
              />
            </FormGroup>
            <SubmitButton type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Enviando...' : 'Enviar'}
              {isSubmitting && <LoadingSpinner />}
            </SubmitButton>

            {submitStatus === 'success' && (
              <StatusMessage status="success">
                ¡Mensaje enviado con éxito! Nos pondremos en contacto contigo pronto.
              </StatusMessage>
            )}

            {submitStatus === 'error' && (
              <StatusMessage status="error">
                Hubo un error al enviar el mensaje. Por favor, intenta nuevamente.
              </StatusMessage>
            )}
          </Form>
        </FormContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

export default RegisterForm;
