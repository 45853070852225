import React from 'react';
import styled from 'styled-components';
import Logo from '../Logo';

const FooterContainer = styled.footer`
  background-color: white;
  padding: 2rem 0;
  border-top: 1px solid #eaeaea;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const LogoContainer = styled.div`
  width: 120px;
  margin-bottom: 1rem;
`;

const Copyright = styled.p`
  color: #666;
  font-size: 0.9rem;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <LogoContainer>
          <Logo light={false} />
        </LogoContainer>
        <Copyright>
          © {new Date().getFullYear()} ZION. Todos los derechos reservados.
        </Copyright>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer; 