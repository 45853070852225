import React from 'react';
import styled from 'styled-components';

const NominaContainer = styled.section`
  padding: 120px 0;
  background-color: #f8f9fa;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: #1C212D;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: #666;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 3rem;
`;

const FeaturesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const FeatureIcon = styled.div`
  font-size: 2.5rem;
  color: #F3BA30;
  margin-bottom: 1rem;
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  color: #1C212D;
  margin-bottom: 1rem;
`;

const FeatureDescription = styled.p`
  color: #666;
  line-height: 1.6;
`;

const NominaComponent = () => {
  return (
    <NominaContainer id="nomina">
      <ContentWrapper>
        <Title>Nómina de Sueldos</Title>
        <Subtitle>
          Sistema integral para la gestión eficiente de la nómina de tu empresa
        </Subtitle>
        <FeaturesContainer>
          <FeatureCard>
            <FeatureIcon>📊</FeatureIcon>
            <FeatureTitle>Cálculos Automáticos</FeatureTitle>
            <FeatureDescription>
              Automatización de cálculos de sueldos, impuestos y deducciones.
            </FeatureDescription>
          </FeatureCard>

          <FeatureCard>
            <FeatureIcon>📝</FeatureIcon>
            <FeatureTitle>Gestión de Documentos</FeatureTitle>
            <FeatureDescription>
              Generación automática de boletas y documentos laborales.
            </FeatureDescription>
          </FeatureCard>

          <FeatureCard>
            <FeatureIcon>📱</FeatureIcon>
            <FeatureTitle>Portal del Empleado</FeatureTitle>
            <FeatureDescription>
              Acceso para empleados a sus datos y documentos laborales.
            </FeatureDescription>
          </FeatureCard>
        </FeaturesContainer>
      </ContentWrapper>
    </NominaContainer>
  );
};

export default NominaComponent; 