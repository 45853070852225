import React from 'react';
import styled from 'styled-components';

const BeneficiosContainer = styled.section`
  padding: 120px 0;
  background-color: white;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: #1C212D;
  margin-bottom: 3rem;
  text-align: center;
`;

const BeneficiosGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const BeneficioCard = styled.div`
  display: flex;
  gap: 2rem;
  align-items: flex-start;
`;

const IconContainer = styled.div`
  font-size: 2.5rem;
  color: #F3BA30;
`;

const TextContainer = styled.div``;

const BeneficioTitle = styled.h3`
  font-size: 1.5rem;
  color: #1C212D;
  margin-bottom: 1rem;
`;

const BeneficioDescription = styled.p`
  color: #666;
  line-height: 1.6;
`;

const Beneficios = () => {
  return (
    <BeneficiosContainer id="beneficios">
      <ContentWrapper>
        <Title>Beneficios del Sistema</Title>
        <BeneficiosGrid>
          <BeneficioCard>
            <IconContainer>💰</IconContainer>
            <TextContainer>
              <BeneficioTitle>Ahorro de Costos</BeneficioTitle>
              <BeneficioDescription>
                Optimiza tus recursos y reduce gastos operativos con nuestro sistema integrado.
              </BeneficioDescription>
            </TextContainer>
          </BeneficioCard>

          <BeneficioCard>
            <IconContainer>⚡</IconContainer>
            <TextContainer>
              <BeneficioTitle>Mayor Productividad</BeneficioTitle>
              <BeneficioDescription>
                Automatiza procesos y mejora la eficiencia de tu equipo.
              </BeneficioDescription>
            </TextContainer>
          </BeneficioCard>

          <BeneficioCard>
            <IconContainer>📈</IconContainer>
            <TextContainer>
              <BeneficioTitle>Crecimiento Escalable</BeneficioTitle>
              <BeneficioDescription>
                Sistema adaptable que crece junto con tu empresa.
              </BeneficioDescription>
            </TextContainer>
          </BeneficioCard>

          <BeneficioCard>
            <IconContainer>🎯</IconContainer>
            <TextContainer>
              <BeneficioTitle>Decisiones Informadas</BeneficioTitle>
              <BeneficioDescription>
                Accede a datos precisos para tomar mejores decisiones empresariales.
              </BeneficioDescription>
            </TextContainer>
          </BeneficioCard>
        </BeneficiosGrid>
      </ContentWrapper>
    </BeneficiosContainer>
  );
};

export default Beneficios; 